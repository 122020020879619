<template>
  <van-empty description="缓存已清空，请关闭后重新进入" />
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import { clearCache } from '@/util/util'

export default {
  components: {
  },
  setup (props, context) {
    const state = reactive({
    })
    const init = () => {
      clearCache({})
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
.bottom-button {
  width: 160px;
  height: 40px;
}
</style>
